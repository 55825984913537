import RegioTariffComparisonDivider from '../RegioTariffComparisonDivider/RegioTariffComparisonDivider';
import styles from './RegioTariffComparisonTariffName.module.scss';
import type { RegioTariffComparisonTariffNameProps } from './interfaces';

const RegioTariffComparisonTariffName = ({
  children,
  color = 'green',
}: RegioTariffComparisonTariffNameProps) => {
  return (
    <div className={styles.base}>
      <RegioTariffComparisonDivider dividerText="Tarif" color={color} />
      <h3 className={styles.name}>{children}</h3>
      <RegioTariffComparisonDivider />
    </div>
  );
};

export default RegioTariffComparisonTariffName;
