import clsx from 'clsx';
import React from 'react';
import { extractClickableProps } from '../../helpers/extractClickableProps';
import { BadgeColor } from '../Badge/consts';
import BadgeRectangular from '../BadgeRectangular/BadgeRectangular';
import Box from '../Box/Box';
import { BoxVariant } from '../Box/consts';
import Button from '../Button/Button';
import { ButtonAlign, ButtonSize, ButtonWidth } from '../Button/consts';
import ButtonLink from '../ButtonLink/ButtonLink';
import Clickable from '../Clickable/Clickable';
import { ClickableAction } from '../Clickable/consts';
import HorizontalSeparator from '../HorizontalSeparator/HorizontalSeparator';
import { SeperatorColor } from '../HorizontalSeparator/interfaces';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import LegacyPicture from '../LegacyPicture/LegacyPicture';
import Popup from '../Popup/Popup';
import ProductOverviewButton from '../ProductOverviewButton/ProductOverviewButton';
import RichText from '../RichText/RichText';
import styles from './ProductOverview.module.scss';
import { ProductOverviewType, StepColor } from './consts';
import type { ProductOverviewProps } from './interfaces';

const getColor = (color: StepColor): SeperatorColor => {
  if (color === StepColor.Orange) {
    return 'orange';
  }

  if (color === StepColor.Turquoise) {
    return 'turquoise';
  }

  return 'surrogat';
};

const ProductOverview = ({
  productOverviewType = ProductOverviewType.Default,
  price,
  priceComment,
  priceExtension,
  savingsAmount,
  buttonWidth,
  buttonSize,
  buttonColor,
  buttonText,
  detailInfoText,
  imageComment,
  headlineFirst,
  headlineSecond,
  badge,
  badgeColor = BadgeColor.Yellow,
  icon,
  stepColor = StepColor.Turquoise,
  image,
  isWithBoxComponent = true,
  details,
  onClickDetails,
  onClickSavingDetails,
  onClickButton,
  isSelected,
  noHoverAnimation,
  tariffId,
  children,
  ...props
}: ProductOverviewProps) => {
  const clickableProps = extractClickableProps(
    onClickButton
      ? { actionType: ClickableAction.Custom, onClick: onClickButton }
      : {
          ...props,
        }
  );

  const handleOnClickDetails = () => onClickDetails?.();

  const baseStyles = clsx(styles.base, {
    [styles.alternative]: productOverviewType !== ProductOverviewType.Default,
    [styles.hover]: !noHoverAnimation,
    [styles.withBadge]: badge,
  });

  const baseNoBoxStyles = clsx(styles.baseNoBox, {
    [styles.alternative]: productOverviewType !== ProductOverviewType.Default,
  });

  const buttonStyles = clsx(
    styles.button,
    price ? styles.buttonMarginDefault : styles.buttonMarginLarge
  );

  const badgeColorStyle: Record<BadgeColor, string> = {
    blue: styles.blue,
    green: styles.green,
    orange: styles.orange,
    yellow: styles.yellow,
    purple: styles.purple,
    turquoise: styles.turquoise,
    white: '',
  };

  const badgeClasses = clsx(styles.badgeBase, badgeColorStyle[badgeColor]);

  const stepColorStyle = styles[stepColor];

  const iconClasses = clsx(styles.iconBig);

  const isOldTariff = tariffId?.includes('-F-') ?? false;

  const defaultContent = (
    <>
      {badge && typeof badge === 'string' && (
        <BadgeRectangular additionalClassName={styles.badge} text={badge} />
      )}
      <div className={styles.headlineFirst}>{headlineFirst}</div>
      {headlineSecond && (
        <div
          className={
            headlineSecond
              ? styles.headlineSecond
              : styles.headlineSecondDisabled
          }
        >
          {headlineSecond}
        </div>
      )}
      {image && (
        <div className={styles.imageWrapper}>
          <Image
            image={image}
            caption={imageComment}
            actionType={ClickableAction.None}
          />
        </div>
      )}
      {details && (
        <>
          <HorizontalSeparator color={getColor(stepColor)} />
          <RichText size="small" listWeight="regular">
            {details as React.ReactNode}
          </RichText>
        </>
      )}
      <div className={styles.bottomGroup}>
        {(price || priceComment || buttonText || detailInfoText) && (
          <HorizontalSeparator color={getColor(stepColor)} />
        )}
        {price && (
          <div className={styles.priceWrapper}>
            <div className={styles.price}>
              <span className={styles.priceGradient}>{price}</span>
            </div>
          </div>
        )}

        {priceComment && (
          <div className={styles.priceComment}>{priceComment}</div>
        )}

        {priceExtension && (
          <div className={styles.priceExtension}>
            <ul className={styles.priceExtensionInfos}>
              {priceExtension.infos.map(({ label, price }, idx) => (
                <li key={idx} className={styles.priceExtensionInfo}>
                  <span>{label}</span>
                  <strong>{price}</strong>
                </li>
              ))}
            </ul>
            <small className={styles.priceExtensionFootnote}>
              {priceExtension.footnote}
            </small>
          </div>
        )}
        {buttonText && (
          <Button
            width={buttonWidth}
            size={buttonSize}
            color={buttonColor}
            align={ButtonAlign.Center}
            className={buttonStyles}
            clickContext={`${tariffId || headlineFirst}${
              !isWithBoxComponent ? ' (modal)' : ''
            }`}
            {...clickableProps}
          >
            {buttonText}
          </Button>
        )}
        {detailInfoText && (
          <ButtonLink
            actionType={ClickableAction.Custom}
            onClick={handleOnClickDetails}
            align={ButtonAlign.Center}
            className={styles.detailButton}
            customDataLayer={{ customAction: 'showDetails' }}
            clickContext={tariffId || headlineFirst}
          >
            {detailInfoText}
          </ButtonLink>
        )}
      </div>
    </>
  );

  const alternativeContent = (
    <>
      {badge && <div className={badgeClasses}>{badge}</div>}

      <div className={styles.headlineWrapper}>
        {headlineSecond && (
          <div
            className={
              headlineSecond
                ? styles.headlineSecond
                : styles.headlineSecondDisabled
            }
          >
            {headlineSecond}
          </div>
        )}
        <div className={styles.headlineFirst}>{headlineFirst}</div>
      </div>
      {icon && <LegacyPicture asset={icon} className={iconClasses} />}

      <div className={styles.priceWrapper}>
        <div className={styles.price}>
          <span className={styles.priceGradient}>{price}</span>
        </div>
      </div>

      {priceComment &&
        (isOldTariff ? (
          <Popup
            description={
              'Der Preis pro Monat basiert auf dem von Ihnen angegebenen Jahresverbrauch und ist nicht identisch mit Ihrer monatlichen Abschlagszahlung. Der Gesamtpreis (Jahr) wird für eine bessere Vergleichbarkeit durch zwölf geteilt und setzt sich aus dem Arbeitspreis und dem Grundpreis zusammen.'
            }
          >
            <div
              className={clsx(styles.priceComment, {
                [styles.priceCommentClickable]: isOldTariff,
              })}
            >
              {priceComment}
            </div>
          </Popup>
        ) : (
          <div className={styles.priceComment}>{priceComment}</div>
        ))}

      {detailInfoText && (
        <div className={styles.detailLinkWrapper}>
          <Clickable
            className={styles.detailLink}
            actionType={ClickableAction.Custom}
            onClick={handleOnClickDetails}
          >
            {detailInfoText}
          </Clickable>
        </div>
      )}

      {savingsAmount && (
        <Clickable
          className={styles.savingsInfoLink}
          actionType={ClickableAction.Custom}
          onClick={() => onClickSavingDetails?.()}
          clickContext={tariffId}
          customDataLayer={{ customAction: 'savingsGVV' }}
        >
          <div className={clsx(styles.savings, stepColorStyle)}>
            <div className={styles.savingsInfo}>
              <Icon
                className={stepColorStyle}
                variant={'user/information'}
                size="iconSize20"
              />
            </div>
            <div className={styles.savingsHeadline}>
              {savingsAmount} Ersparnis
            </div>
            <div className={styles.savingsFootnote}>
              im Vergleich zum Grundversorger
            </div>
          </div>
        </Clickable>
      )}

      {!savingsAmount && <HorizontalSeparator color={getColor(stepColor)} />}

      <RichText
        size="small"
        listWeight="regular"
        className={clsx(styles.detailsList, stepColorStyle)}
      >
        {details as React.ReactNode}
      </RichText>

      {priceExtension && <HorizontalSeparator color={getColor(stepColor)} />}

      {priceExtension && (
        <div className={styles.priceExtension}>
          <ul className={styles.priceExtensionInfos}>
            {priceExtension.infos.map(({ label, price }, idx) => (
              <li key={idx} className={styles.priceExtensionInfo}>
                <span>{label}</span>
                <strong>{price}</strong>
              </li>
            ))}
          </ul>
          <small className={styles.priceExtensionFootnote}>
            {priceExtension.footnote}
          </small>
        </div>
      )}

      {buttonText && (
        <ProductOverviewButton
          stepColor={stepColor}
          width={ButtonWidth.Full}
          size={ButtonSize.Medium}
          align={ButtonAlign.Center}
          clickContext={tariffId ?? headlineFirst}
          {...clickableProps}
          isActive={isSelected}
        >
          {buttonText}
        </ProductOverviewButton>
      )}
    </>
  );

  return (
    <>
      {isWithBoxComponent ? (
        <div className={baseStyles}>
          <Box
            className={clsx(styles.box)}
            variant={
              productOverviewType === ProductOverviewType.PowerAndGas
                ? BoxVariant.Alternative
                : BoxVariant.Default
            }
          >
            {productOverviewType === ProductOverviewType.Default
              ? defaultContent
              : alternativeContent}
            {children}
          </Box>
        </div>
      ) : (
        <div className={baseNoBoxStyles}>
          {productOverviewType === ProductOverviewType.Default
            ? defaultContent
            : alternativeContent}
          {children}
        </div>
      )}
    </>
  );
};

export default ProductOverview;
