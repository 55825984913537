import type { Tariff } from '../models/tariff';
import { formatNumber } from '../uikit/helpers/formatNumber';
import calcRabatPrice from './calcRabatPrice';
import convertGermanFormatToNumber from './convertGermanFormatToNumber';

export const sumTariffOptionRabat = (tariff: Tariff): number =>
  tariff.options?.reduce(
    (sum: number, option) =>
      option.preselected ? sum + (+option.value || 0) : sum,
    0
  ) || 0;

export const calcTariffPriceWithRabat = (tariff: Tariff): string => {
  if (typeof tariff.options === 'undefined' || tariff.options.length === 0) {
    return tariff.prices.monthly;
  }

  const price = convertGermanFormatToNumber(tariff.prices.monthly);
  const priceWithRabat = calcRabatPrice(price, sumTariffOptionRabat(tariff));

  return formatNumber(priceWithRabat, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};
