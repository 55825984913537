import { Asset } from '@uikit/types';

export interface TariffsRequest {
  productType: string;
  city: string;
  zipcode: string;
  consumption: number;
  street?: string;
  streetNumber?: string;
  streetName?: string;
}

export interface TariffsResponse {
  tariffs: Tariff[];
  requestedData: RequestedData;
}

export interface RequestedData {
  zipcode: string;
  city: string;
  consumption: number;
  productType: string;
}

export interface TariffOption {
  id: string;
  name: string;
  value: number;
  unit: string;
  required?: boolean;
  preselected?: boolean;
}

export interface TariffBase {
  prices: Prices;
  attachments?: Asset[];
  productType: 'power' | 'gas';
  id: string;
  prio: string;
  name: string;
  information: Information;
  options?: TariffOption[];
  taxRate: number;
  icon?: Asset;
  bonus?: PlatformBonus;
}

export interface PublicProductInfoProps {
  name: string;
  id: string;
  prio: string;
  attachments?: Asset[];
  information: Information;
  icon?: Asset;
  zipCodeRestrictions?: string[];
  zipCodeRestrictionMode?: string;
}

export interface TariffOldPlatform extends TariffBase {
  platform: EnercityTariffsVersion.Old;
}

export interface TariffBothPlatform extends TariffBase {
  platform: EnercityTariffsVersion.Both;
  newPlatformInfo?: unknown;
}

type TarifPriceZonePrices = {
  basePrice: number;
  usagePrice: number;
};

type TarifPriceZoneUsageRange = {
  from: number;
  to: number;
};

export type TarifPriceZone = {
  prices: TarifPriceZonePrices;
  usageRange: TarifPriceZoneUsageRange;
};

export interface TarifNewPlatform extends TariffBase {
  platform: EnercityTariffsVersion.New;
  newPlatformInfo: unknown;
  zones?: TarifPriceZone[];
}

export type Tariff = Readonly<
  TariffOldPlatform | TarifNewPlatform | TariffBothPlatform
>;

export enum EnercityTariffsVersion {
  Old = 'old',
  New = 'new',
  Both = 'both',
}

export interface Information {
  subtitle?: string;
  badge?: string;
  shortDescription: string[];
  moreInfo: string;
}

export interface Prices {
  monthly: string;
  yearly: string;
  base: string;
  unit: string;
}

export enum ZipcodeRestrictionMode {
  Include = 'include',
  Exclude = 'exclude',
}

export interface PlatformBonus {
  bonusId: string; // ex. '8ae674e2-3ada-470b-b710-3f92c2e28281'
  product: string; // product tariff id ex. ELECTRICITY_SUBSCRIPTION_BASE_12'
  amount: string; // ex. 100
  description: string; // ex. 'Neoskop Example Bonus'
  payoutDate: string; // ex.'30'
}
