import clsx from 'clsx';
import React from 'react';
import MasonryLayout from '../MasonryLayout/MasonryLayout';
import Slider from '../Slider/Slider';
import styles from './ComponentList.module.scss';
import { JustifyContent, SliderActivity } from './consts';
import type { ComponentListProps } from './interfaces';

const ComponentList = ({
  children,
  className,
  colsAmount = 2,
  justifyChild = JustifyContent.Center,
  hasSlider = SliderActivity.Disabled,
}: ComponentListProps) => {
  const colsClasses = [
    styles.noEqualCols,
    styles.oneCol,
    styles.twoCols,
    styles.threeCols,
    styles.fourCols,
  ];

  const classes = clsx(
    styles.list,
    colsClasses[colsAmount],
    styles[justifyChild],
    className
  );

  if (hasSlider === SliderActivity.Enabled) {
    return <Slider className={className}>{children}</Slider>;
  }

  if (hasSlider === SliderActivity.Masonry) {
    return <MasonryLayout colsAmount={colsAmount}>{children}</MasonryLayout>;
  }

  return <div className={classes}>{children}</div>;
};

export default ComponentList;
