export enum ProductOverviewType {
  Default = 'default',
  PowerAndGas = 'power-and-gas',
}

export enum StepColor {
  Yellow = 'yellow',
  Green = 'green',
  Turquoise = 'turquoise',
  Blue = 'blue',
  Orange = 'orange',
  Purple = 'purple',
  Surrogate = 'surrogate',
}
