import React from 'react';
import type { RegioTariffComparisonCardProps } from './interfaces';
import Logo from '@uikit/components/Logo/Logo';
import styles from './RegioTariffComparisonCard.module.scss';
import clsx from 'clsx';

const RegioTariffComparisonCard = ({
  children,
  showLogo,
  size = 'default',
}: RegioTariffComparisonCardProps) => {
  return (
    <div className={clsx(styles.base, styles[size])}>
      {showLogo && <Logo className={styles.logo} />}
      {children}
    </div>
  );
};

export default RegioTariffComparisonCard;
