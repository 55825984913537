import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import styles from './Accent.module.scss';
import type { AccentProps } from './types';

export const withAccent = <T extends { className?: string }>(
  Component: FC<T>
) => {
  const WrappedComponent: FC<T & { color?: AccentProps['color'] }> = (
    props
  ) => {
    return (
      <Component
        {...props}
        className={clsx(
          styles.base,
          props.className,
          props.color ? styles[props.color] : styles.reset
        )}
      />
    );
  };

  WrappedComponent.displayName = `withAccent(${
    Component.displayName || Component.name
  })`;

  return WrappedComponent;
};

const Accent = ({
  color,
  renderWrapper = (className: string, children: ReactNode) => (
    <div className={className}>{children}</div>
  ),
  children,
}: AccentProps) => {
  const className = clsx(styles.base, color ? styles[color] : styles.reset);
  return renderWrapper(className, children);
};

export default Accent;
