import { PRODUCT_TYPES } from '@/helpers/fetchTariffs/consts';
import type {
  ProductType,
  TariffResponseData,
} from '@/helpers/fetchTariffs/types';

const hasMultiProduct = (data?: TariffResponseData | null): boolean =>
  !!data &&
  PRODUCT_TYPES.every(
    (productType: ProductType) => data[productType]?.tariffs?.length
  );

export default hasMultiProduct;
