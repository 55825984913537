import convertGermanFormatToNumber from '@/helpers/convertGermanFormatToNumber';
import { BaseProviderTariff } from '@/helpers/fetchTariffs/types';
import { Tariff } from '@/models/tariff';

const getTariffSavingsAmount = (
  tariff: Tariff | undefined,
  baseProvider: BaseProviderTariff | undefined
) => {
  if (!baseProvider?.yearlyPrice || !tariff?.prices?.yearly) {
    return 0;
  }

  return (
    convertGermanFormatToNumber(baseProvider.yearlyPrice) -
    convertGermanFormatToNumber(tariff.prices.yearly)
  );
};

export default getTariffSavingsAmount;
