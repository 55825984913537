import { Tariff } from '@/models/tariff';
import convertGermanFormatToNumber from '../../helpers/convertGermanFormatToNumber';

const getCheapestTariff = (tariffs: Tariff[] | undefined) => {
  const clonedTariffs = structuredClone(tariffs) ?? [];
  clonedTariffs.sort((a, b) => {
    if (!a.prices || !b.prices) {
      return 0;
    }

    return (
      convertGermanFormatToNumber(a.prices.yearly) -
      convertGermanFormatToNumber(b.prices.yearly)
    );
  });
  return clonedTariffs[0];
};

export default getCheapestTariff;
