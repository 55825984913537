import useKeyPress from '@/hooks/useKeyPress/useKeyPress';
import useOnClickOutside from '@/hooks/useOnClickOutside/useOnClickOutside';
import clsx from 'clsx';
import React from 'react';
import BoxWithArrow from '../BoxWithArrow/BoxWithArrow';
import { BoxArrowPosition } from '../BoxWithArrow/interfaces';
import type { PopupProps } from './interfaces';
import styles from './Popup.module.scss';

const Popup = ({ headline, description, className, children }: PopupProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const closePopup = () => setIsOpen(() => false);
  const openPopup = () => setIsOpen(() => true);

  useOnClickOutside(ref, closePopup);

  useKeyPress('Escape', { onKeyDown: closePopup });

  return (
    <div
      ref={ref}
      onMouseEnter={openPopup}
      onMouseLeave={closePopup}
      onTouchStart={openPopup}
      className={className}
    >
      {children}

      <BoxWithArrow
        className={clsx(styles.popup, { [styles.isVisible]: isOpen })}
        ArrowPosition={BoxArrowPosition.topCenter}
      >
        {headline && <h2 className={styles.headline}>{headline}</h2>}
        <p className={styles.description}>{description}</p>
      </BoxWithArrow>
    </div>
  );
};

export default Popup;
