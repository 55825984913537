import { withAccent } from '@uikit/components/Accent/Accent';
import clsx from 'clsx';
import styles from './RegioTariffComparisonDivider.module.scss';
import type { RegioTariffComparisonDividerProps } from './interfaces';

const RegioTariffComparisonDivider = ({
  dividerText,
  className,
}: RegioTariffComparisonDividerProps) => {
  return (
    <div className={clsx(styles.base, className)}>
      {dividerText && <span className={styles.text}>{dividerText}</span>}
    </div>
  );
};

export default withAccent(RegioTariffComparisonDivider);
