export interface BoxWithArrowProps {
  ArrowPosition: BoxArrowPosition;
  className?: string;
  children: any;
  reducedShadow?: boolean;
}

export enum BoxArrowPosition {
  topLeft = 'topLeft',
  topCenter = 'topCenter',
  topRight = 'topRight',
  left = 'left',
  right = 'right',
  bottomLeft = 'bottomLeft',
  bottomCenter = 'bottomCenter',
  bottomRight = 'bottomRight',
}
