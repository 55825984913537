const REPLACERS: { [key: string]: [RegExp, string] } = {
  strong: [/\*\*(.+?)(\*\*)/gm, '<strong class="strong-surrogate">$1</strong>'],
  italic: [/_([^_]+?)(_)/gm, '<em>$1</em>'],
  underline: [/~([^~]+?)(~)/gm, '<u>$1</u>'],
  breakLine: [/(\\n)|(\n)/gm, '<br/>'],
};

type Options = {
  breakLineTag?: 'br' | 'p';
};

export default function parseCustomMarkdown(
  value: string,
  { breakLineTag = 'br' }: Options = {}
): string {
  const output = Object.values(REPLACERS).reduce(
    (output, replacer) => output.replace(...replacer),
    value
  );

  if (breakLineTag === 'p') {
    return output
      .split('<br/>')
      .filter((paragraph) => paragraph.trim() !== '')
      .reduce((acc, curr) => `${acc}<p>${curr}</p>`, '');
  }

  return output;
}
