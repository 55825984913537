import type {
  Products,
  ResponseProduct,
  TariffResponseData,
} from '@/helpers/fetchTariffs/types';
import sortTariffs from '@/helpers/sortTariffs/sortTariffs';
import { TariffSortOption } from '@/helpers/sortTariffs/types';

const sortResponsedTariffs = (
  data: TariffResponseData,
  sortOption?: TariffSortOption
) => {
  return Object.entries(data).reduce<Products<ResponseProduct>>(
    (acc, [productType, values]) => {
      if (values?.tariffs) {
        return {
          ...acc,
          [productType]: {
            ...values,
            tariffs: sortTariffs(values?.tariffs, sortOption),
          },
        };
      }
      return acc;
    },
    {}
  );
};

export default sortResponsedTariffs;
