import React from 'react';
import type { RegioTariffComparisonProviderNameProps } from './interfaces';
import styles from './RegioTariffComparisonProviderName.module.scss';

const RegioTariffComparisonProviderName = ({
  children,
}: RegioTariffComparisonProviderNameProps) => {
  return (
    <div className={styles.base}>
      <h3 className={styles.name}>{children}</h3>
      <div className={styles.description}>Ihr Grundversorger</div>
    </div>
  );
};

export default RegioTariffComparisonProviderName;
