export enum JustifyContent {
  Center = 'center',
  Left = 'left',
  Right = 'right',
  Stretch = 'stretch',
}

export enum SliderActivity {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Masonry = 'masonry',
}
