import convertGermanFormatToNumber from '@/helpers/convertGermanFormatToNumber';
import { Tariff } from '@/models/tariff';
import { formatNumber } from '@uikit/helpers/formatNumber';

/**
 * @deprecated
 */
export const getPricePerYear = (tariff: Tariff) => {
  let pricePerYear = tariff.prices.yearly;
  if (tariff.bonus) {
    const pricePerYearAsNumber = convertGermanFormatToNumber(pricePerYear);
    const bonusAsNumber = convertGermanFormatToNumber(tariff.bonus.amount);
    const newYearlyPrice = pricePerYearAsNumber - bonusAsNumber;
    pricePerYear = formatNumber(newYearlyPrice, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return pricePerYear;
};
