export interface FormatNumberOptions {
  minimumFractionDigits: number;
  maximumFractionDigits: number;
}

export function formatNumber(
  value: number,
  options: FormatNumberOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }
): string {
  const { minimumFractionDigits, maximumFractionDigits } = options;
  return value.toLocaleString('de-de', {
    minimumFractionDigits,
    maximumFractionDigits,
  });
}

export function formatNumberWithFraction(value: number): string {
  return value.toLocaleString('de-de', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
}

export const formatNumberWithSuffix = (
  value: number,
  singular: string,
  plural?: string
) => `${formatNumberWithFraction(value)} ${value <= 1 ? singular : plural}`;

export const formatCurrency = (value: number, symbol = '€') =>
  formatNumber(value, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }) +
  ' ' +
  symbol;
