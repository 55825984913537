import { getSafeInnerHTML } from '@/helpers/getSafeInnerHTML';
import clsx from 'clsx';
import { extractClickableProps } from '../../helpers/extractClickableProps';
import { ButtonAlign } from '../Button/consts';
import ButtonLink from '../ButtonLink/ButtonLink';
import Icon from '../Icon/Icon';
import RichText from '../RichText/RichText';
import type { TeaserProps } from './interfaces';
import styles from './Teaser.module.scss';

const Teaser = ({
  icon,
  isIcon,
  headline,
  children,
  buttonLabel,
  center = false,
  isButtonVisible = false,
  headlineTag: HeadlineTag = 'h4',
  ...props
}: TeaserProps): JSX.Element => {
  const clickableProps = extractClickableProps(props);

  return (
    <div className={clsx(styles.base, { [styles.center]: center })}>
      {icon && isIcon && (
        <span className={styles.iconWrapper}>
          <Icon
            className={styles.icon}
            variant={icon}
            size="iconSize64"
            isOutlined
          />
        </span>
      )}

      <div className={styles.wrapper}>
        <HeadlineTag
          className={styles.headline}
          dangerouslySetInnerHTML={{ __html: getSafeInnerHTML(headline) }}
        />

        <RichText className={styles.content} autoColor={true}>
          <p>{children}</p>
        </RichText>

        {isButtonVisible && buttonLabel && (
          <ButtonLink
            className={styles.link}
            align={center ? ButtonAlign.Center : ButtonAlign.Left}
            {...clickableProps}
          >
            {buttonLabel}
          </ButtonLink>
        )}
      </div>
    </div>
  );
};

export default Teaser;
