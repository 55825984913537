import clsx from 'clsx';
import styles from './CookieSettings.module.scss';
import type { CookieSettingsProps } from './interfaces';

const CookieSettings = ({ className }: CookieSettingsProps) => {
  return (
    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
    <a
      href="#"
      className={clsx('cta_', styles.base, className)}
      onClick={($event) => {
        $event.preventDefault();

        // This is from Consent Manager to Open the Modal
        window.__cmp?.('showScreenAdvanced');
      }}
    >
      Privatsphäre-Einstellungen
    </a>
  );
};

export default CookieSettings;
