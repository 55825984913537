import clsx from 'clsx';
import React from 'react';
import type { SpacerProps } from './interfaces';
import styles from './Spacer.module.scss';

const Spacer = (sizes: SpacerProps) => {
  const classes = [styles.base];

  Object.keys(sizes).forEach((size) => {
    if (size in sizes) {
      const _size = size as keyof typeof sizes;
      const value = sizes[_size];
      const num = value ? +value * 10 : 0;
      classes.push(styles[`${_size}${num}` as unknown as keyof typeof styles]);
    }
  });

  return <div className={clsx(classes)} />;
};

export default Spacer;
