import { PageHeadProps } from '@/components/PageHead/interfaces';
import { PageModel } from '@/models/page';
import getImageFromStageArea from './getImageFromStageArea';

export default function extractPageMetaFromRouteData<T>(
  { props, path, contents }: PageModel<T>,
  customMetaProps?: Partial<PageHeadProps>
): PageHeadProps {
  const {
    title,
    seoTitle,
    description,
    seoDescription,
    noindex,
    canonical,
    ogTitle,
    ogDescription,
    ogImage: _ogImage,
    image,
  } = props;

  const ogImage =
    (_ogImage?.path ? _ogImage : getImageFromStageArea(contents)) ?? image;

  return {
    title: seoTitle ?? title,
    description: seoDescription ?? description,
    noindex,
    canonical,
    path,
    ogTitle,
    ogDescription,
    ogImage,
    ...customMetaProps,
  };
}
