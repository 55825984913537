import clsx from 'clsx';
import styles from './BoxWithArrow.module.scss';
import type { BoxWithArrowProps } from './interfaces';

const BoxWithArrow = ({
  ArrowPosition,
  children,
  className,
  reducedShadow = false,
}: BoxWithArrowProps) => {
  return (
    <div
      className={clsx(
        styles.base,
        styles[ArrowPosition],
        styles.arrow,
        { [styles.reducedShadow]: reducedShadow },
        className
      )}
    >
      {children}
    </div>
  );
};

export default BoxWithArrow;
