import Button from '@uikit/components/Button/Button';
import clsx from 'clsx';
import React from 'react';
import type { ProductOverviewButtonProps } from './interfaces';
import styles from './ProductOverviewButton.module.scss';

const ProductOverviewButton = ({
  stepColor,
  isActive,
  ...props
}: ProductOverviewButtonProps) => {
  const stepColorStyle = styles[stepColor];

  return (
    <Button
      className={clsx(styles.base, stepColorStyle, {
        [styles.active]: isActive,
      })}
      {...props}
    />
  );
};

export default ProductOverviewButton;
