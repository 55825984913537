import clsx from 'clsx';
import React from 'react';
import styles from './HorizontalSeparator.module.scss';
import type { HorizontalSeparatorProps } from './interfaces';

const HorizontalSeparator = ({
  color = 'gray',
  margin = 'default',
}: HorizontalSeparatorProps) => {
  return <hr className={clsx(styles.base, styles[color], styles[margin])} />;
};

export default HorizontalSeparator;
