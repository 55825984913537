import * as Tabs from '@radix-ui/react-tabs';
import { formatCurrency } from '@uikit/helpers/formatNumber';
import clsx from 'clsx';
import { useState } from 'react';
import { extractClickableProps } from '../../helpers/extractClickableProps';
import Accent from '../Accent/Accent';
import { toAccentColor } from '../Accent/helpers';
import { BoxWidth } from '../Box/consts';
import ButtonLink from '../ButtonLink/ButtonLink';
import { ClickableAction, ClickableTarget } from '../Clickable/consts';
import ModalCloseIcon from '../Modal/ModalCloseIcon/ModalCloseIcon';
import ModalContent from '../Modal/ModalContent';
import ProductOverview from '../ProductOverview/ProductOverview';
import { ProductOverviewType, StepColor } from '../ProductOverview/consts';
import ProductOverviewPriceTable from '../ProductOverviewPriceTable/ProductOverviewPriceTable';
import RichText from '../RichText/RichText';
import styles from './ProductOverviewModalContent.module.scss';
import type { ProductOverviewModalContentProps } from './interfaces';

const ProductOverviewModalContent = (
  props: ProductOverviewModalContentProps
) => {
  const clickableProps = extractClickableProps(props);

  const {
    productOverviewType = ProductOverviewType.Default,
    price,
    priceComment,
    buttonWidth,
    buttonSize,
    buttonColor,
    details,
    buttonText,
    imageComment,
    headlineFirst,
    headlineSecond,
    onClickDetails,
    onClickButton,
    image,
    isWithBoxComponent = false,
    modalRightSide,
    className,
    priceExtension,
    icon,
    stepColor,
    attachments,
    onClose,
    tariffId,
    zonesInfos,
  } = props;

  const classesLeft = clsx(styles.sides, styles.left);
  const classesRight = clsx(styles.sides, styles.right);
  const [tabValue, setTabValue] = useState('description');

  return (
    <ModalContent noPadding width={BoxWidth.Large} noMargin maxHeight>
      {onClose && <ModalCloseIcon onClick={onClose} />}
      <Accent color={toAccentColor(stepColor)}>
        <div
          className={clsx(styles.base, className, {
            [styles.orange]: stepColor === StepColor.Orange,
            [styles.turquoise]: stepColor === StepColor.Turquoise,
          })}
        >
          <div className={classesLeft}>
            <div className={styles.innerLeft}>
              <ProductOverview
                tariffId={tariffId}
                priceExtension={priceExtension}
                productOverviewType={productOverviewType}
                noHoverAnimation
                imageComment={imageComment}
                price={price}
                priceComment={priceComment}
                buttonText={buttonText}
                buttonSize={buttonSize}
                buttonWidth={buttonWidth}
                buttonColor={buttonColor}
                headlineFirst={headlineFirst}
                headlineSecond={headlineSecond}
                onClickDetails={onClickDetails}
                onClickButton={onClickButton}
                image={image}
                isWithBoxComponent={isWithBoxComponent}
                details={details}
                icon={icon}
                stepColor={stepColor}
                {...clickableProps}
              >
                {zonesInfos?.info && (
                  <div className={styles.detailsSummary}>
                    {zonesInfos.info}
                    <button type="button" onClick={() => setTabValue('prices')}>
                      Preisliste anzeigen
                    </button>
                  </div>
                )}
              </ProductOverview>
            </div>
          </div>
          <div className={classesRight}>
            {zonesInfos ? (
              <Tabs.Root
                defaultValue="description"
                orientation="vertical"
                className={styles.tabsRoot}
                onValueChange={setTabValue}
                value={tabValue}
              >
                <Tabs.List
                  aria-label="Tarifdetails"
                  className={styles.tabsList}
                >
                  <Tabs.Trigger
                    value="description"
                    className={styles.tabsTrigger}
                  >
                    <span>Beschreibung</span>
                  </Tabs.Trigger>
                  <Tabs.Trigger value="prices" className={styles.tabsTrigger}>
                    <span>Preisliste</span>
                  </Tabs.Trigger>
                </Tabs.List>

                <Tabs.Content
                  value="description"
                  className={styles.tabsContent}
                  tabIndex={-1}
                >
                  <RichText className={styles.rightDescription}>
                    {modalRightSide}
                  </RichText>
                </Tabs.Content>

                <Tabs.Content
                  value="prices"
                  className={styles.tabsContent}
                  tabIndex={-1}
                >
                  {zonesInfos?.zones.map(({ basePrice, title, usagePrice }) => {
                    return (
                      <ProductOverviewPriceTable title={title} key={title}>
                        <ProductOverviewPriceTable.Row key="usagePrice">
                          <span>Arbeitspreis pro kWh</span>
                          <span>{formatCurrency(usagePrice, 'Cent')}</span>
                        </ProductOverviewPriceTable.Row>
                        <ProductOverviewPriceTable.Row key="basePrice">
                          <span>Gesamtpreis pro Jahr</span>
                          <span>{formatCurrency(basePrice)}</span>
                        </ProductOverviewPriceTable.Row>
                      </ProductOverviewPriceTable>
                    );
                  })}
                </Tabs.Content>
              </Tabs.Root>
            ) : (
              <div className={styles.innerRight}>
                <RichText className={styles.rightDescription}>
                  {modalRightSide}
                </RichText>
                {attachments?.map((download) => (
                  <ButtonLink
                    key={download.name}
                    actionType={ClickableAction.Asset}
                    target={ClickableTarget.Blank}
                    asset={download}
                    className={styles.downloads}
                    clickContext={tariffId}
                    download={false}
                  >
                    {download.name}
                  </ButtonLink>
                ))}
              </div>
            )}
          </div>
        </div>
      </Accent>
    </ModalContent>
  );
};

export default ProductOverviewModalContent;
