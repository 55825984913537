import type { Tariff } from '../../models/tariff';
import { calcTariffPriceWithRabat } from '../calcTariffPriceWithRabat';
import convertGermanFormatToNumber from '../convertGermanFormatToNumber';
import type { TariffSorters, TariffSortOption } from './types';

const priceFromTariff = (tarif: Tariff) =>
  convertGermanFormatToNumber(calcTariffPriceWithRabat(tarif));

const tariffSorters: TariffSorters = {
  hightolow: (a, b) => priceFromTariff(b) - priceFromTariff(a),
  lowtohigh: (a, b) => priceFromTariff(a) - priceFromTariff(b),
  custom: (a, b) => +b.prio - +a.prio,
};

const sortTariffs = (
  tariffs: Tariff[],
  sortOption: TariffSortOption = 'none'
): Tariff[] => {
  if (sortOption === 'none') {
    return tariffs;
  }

  const _tariffs: Tariff[] = Array.from(tariffs);
  _tariffs.sort(tariffSorters[sortOption]);

  return _tariffs;
};

export default sortTariffs;
