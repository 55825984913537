export const ACCENT_COLORS = [
  'surrogate',
  'violet',
  'red',
  'green',
  'blue',
  'turquoise',
  'orange',
  'yellow',
] as const;
