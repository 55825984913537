import styles from './ProductOverviewPriceTable.module.scss';
import type {
  ProductOverviewPriceTable,
  ProductOverviewPriceTableRowProps,
} from './types';

const ProductOverviewPriceTableRow = ({
  children,
}: ProductOverviewPriceTableRowProps) => {
  return <dd className={styles.row}>{children}</dd>;
};

const ProductOverviewPriceTable = ({
  title,
  children,
}: ProductOverviewPriceTable) => {
  return (
    <dl className={styles.list}>
      <dt className={styles.title}>{title}</dt>
      {children}
    </dl>
  );
};

export default Object.assign(ProductOverviewPriceTable, {
  Row: ProductOverviewPriceTableRow,
});
