import Masonry from 'react-masonry-css';
import styles from './MasonryLayout.module.scss';
import type { MasonryLayoutProps } from './interfaces';

const MasonryLayout = ({ children, colsAmount }: MasonryLayoutProps) => {
  const breakpointColumns = {
    default: colsAmount,
    992: 2,
    576: 1,
  };

  return (
    <Masonry
      breakpointCols={breakpointColumns}
      className={styles.base}
      columnClassName={styles.column}
    >
      {children}
    </Masonry>
  );
};

export default MasonryLayout;
