import useMeasure from '@/hooks/useMeasure/useMeasure';
import { animated, useSpring } from '@react-spring/web';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import Icon from '../Icon/Icon';
import styles from './Collapsible.module.scss';
import type { CollapsibleProps } from './interfaces';

const Collapsible = ({
  children,
  className,
  iconPosition = 'left',
  collapseDirection = 'top',
  textClosed = 'Mehr anzeigen',
  textOpen = 'Weniger anzeigen',
}: CollapsibleProps) => {
  const [collapsibleOpen, setCollapsibleOpen] = useState(false);
  const childrenRef = useRef<HTMLDivElement>(null);
  const { height } = useMeasure(childrenRef, true);
  const { ...props } = useSpring({
    opacity: collapsibleOpen ? 1 : 0,
    maxHeight: collapsibleOpen ? `${height}px` : '0px',
    config: { duration: 200 },
  });

  return (
    <div
      className={clsx(
        styles.base,
        { [styles.collapseDirectionBottom]: collapseDirection === 'bottom' },
        className
      )}
    >
      <animated.div style={{ ...props }}>
        <div
          className={clsx(styles.container, {
            [styles.isOpen]: collapsibleOpen,
          })}
          ref={childrenRef}
        >
          {children}
        </div>
      </animated.div>
      <div
        className={clsx(styles.button, {
          [styles.buttonReverse]: iconPosition === 'right',
        })}
        onClick={() => {
          setCollapsibleOpen(!collapsibleOpen);
        }}
      >
        <Icon
          size="iconSize24"
          variant="action/chevron-down"
          className={clsx(styles.icon, {
            [styles.isOpen]: collapsibleOpen,
          })}
        />
        {collapsibleOpen ? textOpen : textClosed}
      </div>
    </div>
  );
};

export default Collapsible;
