import React from 'react';
import type { RegioTariffComparisonPriceProps } from './interfaces';
import styles from './RegioTariffComparisonPrice.module.scss';
import clsx from 'clsx';

const RegioTariffComparisonPrice = ({
  children,
  small,
}: RegioTariffComparisonPriceProps) => {
  return (
    <div className={styles.base}>
      <div className={clsx(styles.price, { [styles.small]: small })}>
        {children}
      </div>
      <div className={styles.subText}>pro Monat</div>
    </div>
  );
};

export default RegioTariffComparisonPrice;
