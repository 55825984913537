import convertGermanFormatToNumber from '@/helpers/convertGermanFormatToNumber';
import { Tariff } from '@/models/tariff';
import { formatNumber } from '@uikit/helpers/formatNumber';

/**
 * @deprecated
 */
export const getPricePerMonth = (tariff: Tariff) => {
  let pricePerMonth = tariff.prices.monthly;
  if (tariff.bonus) {
    const pricePerMonthAsNumber = convertGermanFormatToNumber(pricePerMonth);
    const bonusAsNumber = convertGermanFormatToNumber(tariff.bonus.amount);
    const newPricePerMonthAsNumber = pricePerMonthAsNumber - bonusAsNumber / 12;
    pricePerMonth = formatNumber(newPricePerMonthAsNumber, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return pricePerMonth;
};
