import clsx from 'clsx';
import React from 'react';
import parseCustomMarkdown from '../../helpers/parseCustomMarkdown';
import stripHTMLTags from '../../helpers/stripHTMLTags';
import { BadgeColor } from '../Badge/consts';
import styles from './BadgeRectangular.module.scss';
import type { BadgeRectangularProps } from './interfaces';

const BadgeRectangular = ({
  text,
  additionalClassName,
  color = BadgeColor.Turquoise,
  tag = 'div',
}: BadgeRectangularProps) => {
  // @ts-ignore
  const colorStyle = color ? styles[color] : '';
  const className = clsx(colorStyle, styles.base, additionalClassName);
  const clearText = stripHTMLTags(text);
  const Tag = tag;
  return (
    <Tag className={className}>
      {clearText && (
        <div
          dangerouslySetInnerHTML={{ __html: parseCustomMarkdown(clearText) }}
        />
      )}
    </Tag>
  );
};

export default BadgeRectangular;
