import { getJson } from '@/helpers/ajax';
import { transformSuGRProduct } from './transformSuGRProduct';
import type { SuGRTariffsRequestData, SuGRTariffsResponseData } from './types';

const SERVICEKIT_URL = process.env.SERVICEKIT_URL ?? '';
const REGIO_PAGES_CONFIGURATOR_ID =
  process.env.REGIO_PAGES_CONFIGURATOR_ID || '';

export const fetchSuGRTariffs = async ({
  productType,
  ...data
}: SuGRTariffsRequestData) => {
  const sugrTariffsURL = new URL('/api/sugr/tariffs', SERVICEKIT_URL);

  sugrTariffsURL.searchParams.set(
    'configuratorId',
    REGIO_PAGES_CONFIGURATOR_ID
  );

  sugrTariffsURL.searchParams.set('products', productType);

  Object.entries(data)
    .filter(([key, value]) => !!key && !!value)
    .forEach(([key, value]) => {
      sugrTariffsURL.searchParams.set(key, String(value));
    });

  const response = await getJson<SuGRTariffsResponseData>(sugrTariffsURL);

  if (!response?.[productType]) {
    return {};
  }

  return {
    gas: transformSuGRProduct(response, 'gas'),
    power: transformSuGRProduct(response, 'power'),
  };
};
