import React from 'react';
import { LogoVariant } from './consts';
import type { LogoProps } from './interfaces';
import styles from './Logo.module.scss';

const Logo = ({
  variant = LogoVariant.Default,
  className = styles.default,
  small,
  id = 'logo-gradient-coloured',
}: LogoProps) => {
  return small ? (
    <svg
      version="1.1"
      className={className}
      viewBox="0 0 23 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id={id} x1="-.93804%" y1="52.983%" y2="52.983%">
          <stop stopColor="#A1028F" offset="0" />
          <stop stopColor="#FD0004" offset="1" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g
          transform="translate(-16 -20)"
          fill={`url(#${id})`}
          fillRule="nonzero"
        >
          <path d="m27.731 24.174c3.4783 0 5.7549 1.8347 6.0079 5.2174h-12.522c0.63241-3.0387 3.0988-5.2174 6.5138-5.2174zm-11.731 7.8261c0 6.9533 5.0691 12 11.77 12 5.0108 0 8.1571-1.7944 10.896-5.1028l-4.1368-3.2523c-1.2818 2.1308-4.1368 3.4766-6.7588 3.4766-2.9133 0-5.5352-2.1869-6.0013-4.9907h17.072c0.058265-0.61682 0.11653-1.6822 0.11653-2.1308 0-6.5607-3.6707-12-11.245-12-6.6422 0-11.711 5.1028-11.711 12z" />
        </g>
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 132 33"
      className={className}
    >
      {variant === LogoVariant.Default && (
        <defs>
          <linearGradient x1="4.02817919%" y1="50%" x2="100%" y2="50%" id={id}>
            <stop stopColor="#909" offset="0%" />
            <stop stopColor="red" offset="100%" />
          </linearGradient>
        </defs>
      )}
      <path
        d="M92.725 27.567h4.37V8.17h-4.37v19.396zM61.445 16.43v11.136h4.37V16.894c0-3.434 1.3-4.64 4.1-4.64l1.023.046.093-4.176c-.28-.046-.744-.046-1.023-.046-6.228.046-8.552 3.48-8.552 8.352zM9.39 11.93c2.556 0 4.23 1.485 4.415 4.223H4.6c.465-2.46 2.277-4.223 4.787-4.223zM0 17.87c0 5.754 4.044 9.93 9.39 9.93 3.997 0 6.507-1.485 8.692-4.223l-3.3-2.69c-1.023 1.763-3.3 2.877-5.392 2.877-2.324 0-4.415-1.8-4.787-4.13H18.22a26.5 26.5 0 0 0 .093-1.763c0-5.43-2.928-9.93-8.97-9.93C4.044 7.94 0 12.16 0 17.87zm49.64-5.94c2.556 0 4.23 1.485 4.415 4.223h-9.203c.465-2.46 2.277-4.223 4.787-4.223zm-9.39 5.94c0 5.754 4.044 9.93 9.39 9.93 3.997 0 6.507-1.485 8.692-4.223l-3.3-2.69c-1.023 1.763-3.3 2.877-5.392 2.877-2.277 0-4.415-1.8-4.787-4.13H58.47a26.5 26.5 0 0 0 .093-1.763c0-5.43-2.928-9.93-8.97-9.93-5.252 0-9.342 4.223-9.342 9.93zm31.792 0c0 5.568 4.37 9.93 9.946 9.93 3.346 0 6.32-1.67 8.134-4.223l-3.3-2.69c-.976 1.578-2.742 2.69-4.834 2.69-3.254 0-5.53-2.506-5.53-5.707 0-3.248 2.277-5.707 5.53-5.707 2.092 0 3.858 1.114 4.834 2.69l3.3-2.69C88.3 9.61 85.335 7.94 81.99 7.94c-5.577 0-9.946 4.362-9.946 9.93zm-51.22-1.346v11.044h4.37V16.245c0-2.645 1.534-4.315 4.1-4.315s4.1 1.67 4.1 4.315v11.322h4.37V16.523c0-4.687-2.5-8.677-8.506-8.677s-8.413 3.99-8.413 8.677zm80.315-13.92v16.705c0 4.826 2.324 8.306 8.552 8.306.837 0 1.255 0 1.906-.046l-.093-4.176c-.604.046-.883.046-1.906.046-2.835 0-4.1-1.206-4.1-4.64v-6.728h8.506l5.67 15.127-.232.5c-.325.742-.697 1.16-2.23 1.16-.883 0-1.952-.046-3.02-.14l-.093 4.083c1.208.093 2.37.14 3.58.14 3.532 0 5.3-1.438 6.925-5.754l7.158-19.07h-4.694l-4.88 13.828-3.81-10.812c-.65-1.8-1.487-3.016-4.1-3.016h-8.785V2.556h-4.37v.046zM94.956.097c-1.44 0-2.603 1.16-2.603 2.6s1.162 2.6 2.603 2.6 2.603-1.16 2.603-2.6A2.63 2.63 0 0 0 94.956.097z"
        fill={variant === LogoVariant.Default ? `url(#${id})` : variant}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Logo;
