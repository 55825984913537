import { getSafeInnerHTMLProp } from '@/helpers/getSafeInnerHTML';
import parseCustomMarkdown from '@uikit/helpers/parseCustomMarkdown';
import stripHTMLTags from '@uikit/helpers/stripHTMLTags';
import clsx from 'clsx';
import type { RegioBadgeProps } from './interfaces';
import styles from './RegioBadge.module.scss';

const RegioBadge = ({ children, color = 'turquoise' }: RegioBadgeProps) => {
  const pureChildren = stripHTMLTags(children);

  return (
    <div className={clsx(styles.base, styles[color])}>
      <div {...getSafeInnerHTMLProp(parseCustomMarkdown(pureChildren))} />
    </div>
  );
};

export default RegioBadge;
