import { Asset } from '@uikit/types';
import type { PageContentItem } from '@/models/page';

const getImageFromStageArea = (
  items: readonly PageContentItem[]
): Asset | undefined => {
  if (!items?.length) {
    return;
  }

  const stageArea = items.find((item) => item.componentName === 'stage-area');

  return stageArea?.contents?.[0]?.props?.image;
};

export default getImageFromStageArea;
