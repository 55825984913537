/**
 * @deprecated
 */
const getProductDetailsString = (details: string[]): string => {
  const divStart = '<div>';
  const divEnd = '</div>';
  const ulStart = '<ul>';
  const ulEnd = '</ul>';
  const liStart = '<li>';
  const liEnd = '</li>';

  if (details.length === 0) return '';

  let listContent = '';
  listContent += divStart + ulStart;

  details.map((detail) => {
    return (listContent += liStart + detail + liEnd);
  });
  listContent += ulEnd + divEnd;

  return listContent;
};

export default getProductDetailsString;
