import styles from './Badge.module.scss';

export enum BadgeColor {
  Yellow = 'yellow',
  Green = 'green',
  Turquoise = 'turquoise',
  Blue = 'blue',
  Orange = 'orange',
  Purple = 'purple',
  White = 'white',
}

export enum TextSize {
  Tiny = 'tiny',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum BadgeTextType {
  Default = 'default',
  Highlight = 'highlight',
  Special = 'special',
  Medium = 'medium',
}

export enum BadgeSize {
  Tiny = 'size-tiny',
  Small = 'size-small',
  Medium = 'size-medium',
}

export enum BadgeBreakpoint {
  NONE = 'none',
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export const badgeStyles = {
  [BadgeTextType.Default]: styles.textDefault,
  [BadgeTextType.Highlight]: styles.textHighlight,
  [BadgeTextType.Special]: styles.textSpecial,
  [BadgeTextType.Medium]: styles.textMedium,
};

export const badgeMobile = {
  [BadgeBreakpoint.NONE]: styles.mobileNone,
  [BadgeBreakpoint.XS]: styles.mobileXs,
  [BadgeBreakpoint.SM]: styles.mobileSm,
  [BadgeBreakpoint.MD]: styles.mobileMd,
  [BadgeBreakpoint.LG]: styles.mobileLg,
};
