import { TariffResponseData } from '@/helpers/fetchTariffs/types';
import React from 'react';
import { QueryObserverOptions, useQuery } from 'react-query';
import { fetchSuGRTariffs } from './fetchSuGRTariffs';
import hasMultiProduct from './hasMultiProduct';
import sortResponsedTariffs from './sortResponsedTariffs';
import type { UseSuGRTariffsProps } from './types';

const useSuGRTariffs = (
  {
    zipCode,
    city,
    cityKey,
    streetNumber,
    streetName,
    sortOption,
    productType,
    power,
    gas,
  }: UseSuGRTariffsProps,
  queryConfig: QueryObserverOptions<TariffResponseData> = {}
) => {
  const { data, ...restProps } = useQuery<TariffResponseData>(
    [
      'sugrTariffs',
      zipCode,
      city,
      streetNumber,
      streetName,
      productType,
      power,
      gas,
    ],
    async () => {
      return await fetchSuGRTariffs({
        zipCode: zipCode ?? '',
        city: city ?? '',
        cityKey,
        streetNumber,
        streetName,
        productType,
        power,
        gas,
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      enabled: !!zipCode && !!city && (!!power || !!gas),
      ...queryConfig,
    }
  );

  const sortedData = React.useMemo(
    () => data && sortResponsedTariffs(data, sortOption),
    [data, sortOption]
  );

  return {
    data: sortedData,
    hasMultiProduct: !!data && hasMultiProduct(data),
    ...restProps,
  };
};

export default useSuGRTariffs;
