import { format as fnsFormat } from 'date-fns';
import { de } from 'date-fns/locale';

export const shiftDate = (days = 60, date = new Date()): Date =>
  new Date(date.getTime() + days * 24 * 60 * 60 * 1000);

export const parseDate = (value: string): Date => {
  const [day, month, year] = value.split('.');
  return new Date(Date.parse(`${year}-${month}-${day}`));
};

export const isDateFormatValid = (value: string): boolean =>
  /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/.test(
    value
  );

export const lastDayOfMonth = (y: number, m: number): number => {
  return new Date(y, m + 1, 0).getDate();
};

export const hasNoIsoDateSupport = (value: string) => {
  const date = Date.parse(value);

  return isNaN(date);
};

export const safelyParsedDate = (value: string): Date => {
  if (hasNoIsoDateSupport(value)) {
    const [day, month, year] = value.split(/-|\./);

    // the -1 is because month starts at 0 🤔
    return new Date(+year, +month - 1, +day);
  } else {
    return new Date(value);
  }
};

export enum FormatDateType {
  Standard = 'dd.MM.yyyy',
  Article = 'd. MMMM yyyy',
  CalorificValueSLP = 'yyyyMMdd',
  PressArticle = 'dd. MMMM yyyy',
  EventArticle = 'dd. MMMM yyyy',
  EventArticleWeekday = 'EEEE, dd. MMMM yyyy',
  Time = 'kk:mm',
  Long = 'dd.MM.yyyy, HH:mm',
}

export const formatDate = (
  date: Date,
  format = FormatDateType.Standard
): string => {
  return fnsFormat(date, format, { locale: de });
};

export const isDateInFuture = (date: string | Date) => {
  return new Date().getTime() <= new Date(date).getTime();
};

export const durationDate = (startDate: Date, endDate: Date) => {
  return `${formatDate(startDate, FormatDateType.Standard).slice(
    0,
    3
  )} - ${formatDate(endDate, FormatDateType.Standard)}`;
};
